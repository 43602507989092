import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Greeting from '../components/greeting';
import { Link } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPencilAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
// import { useNavigate } from 'react-router-dom';


function ViewRequest() {
    const [request, setRequest] = useState([]);
    const [pettycash, setPettycash] = useState([]);
    const [expenseAdvance, setExpensedvance] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    // const [collapsedMonths, setCollapsedMonths] = useState({});
    const [selectedMonth, setSelectedMonth] = useState("");
    const [selectedMonthpc, setSelectedMonthpc] = useState("");
    const [selectedMonthea, setSelectedMonthea] = useState("");
    const [loading, setLoading] = useState(false);

    const handleFullNameClick = (userId) => {
        setSelectedRow(userId);
    };

    const url = window.location.href;
    const sl = url.includes('sl') || url.includes('3000');
    const lib = url.includes('lib');

    let user = null;
    // let department = null;
    // let baseCountry = null;
    if (JSON.parse(localStorage.getItem('userdata')) !== null) {
        const { 'First Name': username } = JSON.parse(
            localStorage.getItem('userdata')
        );
        user = username;
        // department = Department;
        // baseCountry = baseC;
    }
    // console.log('base country: ', baseCountry);
    // check the url if it has sl in it
    // if it does, then filter the users by Base Country and display them
    // if it doesn't, then display all the users
    // const url = window.location.href;

    // const sl = url.includes('sl') || url.includes('3000');
    // const lib = url.includes('lib');

    // Get the current month
    const currentDate = new Date();
    const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
    // console.log(currentMonth);

    useEffect(() => {
        // console.log('----Requests-----')
        setLoading(true);
        axios.get('https://digital-forms-backend.onrender.com/viewrequest')
            .then(response => {
                const data = response.data;
                // setRequest(data);
                // console.log("Perdiem: ", data);
                if (sl) {
                    const filteredUsers = data.filter(user => user.user['Base Country'] === 'Sierra Leone');
                    setRequest(filteredUsers);
                    setSelectedMonth(currentMonth)
                } else if (lib) {
                    const filteredUsers = data.filter(user => user.user['Base Country'] === 'Liberia');
                    setRequest(filteredUsers);
                    setSelectedMonth(currentMonth);
                }
                // setLoading(false);
                ///////////////////////////// = Second part = ////////////////////////////////////
                axios.get('https://digital-forms-backend.onrender.com/viewpettycash')
                    .then(response => {
                        console.log('--petty data found--')
                        const data = response.data;
                        // setPettycash(data);
                        // console.log(pettycash);
                        // console.log(data);

                        if (sl) {
                            const filteredUsers = data.filter(user => user.user['Base Country'] === 'Sierra Leone' && user.details['urlname'] === 'pettycash');
                            setPettycash(filteredUsers);
                            setSelectedMonthpc(currentMonth);
                            const filteredUsers2 = data.filter(user => user.user['Base Country'] === 'Sierra Leone' && user.details['urlname'] === 'expenseAdvance');
                            setExpensedvance(filteredUsers2);
                            setSelectedMonthea(currentMonth);
                        } else if (lib) {
                            const filteredUsers = data.filter(user => user.user['Base Country'] === 'Liberia' && user.details['urlname'] === 'pettycash');
                            setPettycash(filteredUsers);
                            const filteredUsers2 = data.filter(user => user.user['Base Country'] === 'Sierra Leone' && user.details['urlname'] === 'expenseAdvance');
                            setExpensedvance(filteredUsers2);
                            setSelectedMonthea(currentMonth);
                        }

                        // console.log('currentMonth: ', currentMonth)
                        setLoading(false)
                    })
                    .catch(error => {
                        console.log(error);
                        setLoading(false)
                    });
                ////////////////////////////==end second part ==////////////////////////////////////

            })
            .catch(error => {
                console.log(error);
                // setLoading(false);
            });
    }, []);

    // useEffect(() => {
    //     console.log('----Petty cash-----')
    //     axios.get('https://digital-forms-backend.onrender.com/viewpettycash')
    //         .then(response => {
    //             console.log('--petty data found--')
    //             const data = response.data;
    //             // setPettycash(data);
    //             // console.log(pettycash);
    //             // console.log(data);

    //             if (sl) {
    //                 console.log('--filtering petty --')
    //                 const filteredUsers = data.filter(user => user.user['Base Country'] === 'Sierra Leone' && user.details['urlname'] === 'pettycash');
    //                 setPettycash(filteredUsers);
    //                 setSelectedMonthpc(currentMonth);
    //                 const filteredUsers2 = data.filter(user => user.user['Base Country'] === 'Sierra Leone' && user.details['urlname'] === 'expenseAdvance');
    //                 setExpensedvance(filteredUsers2);
    //                 setSelectedMonthea(currentMonth);
    //                 console.log('--done filtering petty --')
    //             } else if (lib) {
    //                 const filteredUsers = data.filter(user => user.user['Base Country'] === 'Liberia' && user.details['urlname'] === 'pettycash');
    //                 setPettycash(filteredUsers);
    //                 const filteredUsers2 = data.filter(user => user.user['Base Country'] === 'Sierra Leone' && user.details['urlname'] === 'expenseAdvance');
    //                 setExpensedvance(filteredUsers2);
    //                 setSelectedMonthea(currentMonth);
    //             }
    //         })
    //         .catch(error => {
    //             console.log(error);
    //         });
    // }, []);

    // convert the table to excel sheet
    const convertToCSV = (objArray) => {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let str = '';

        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in array[i]) {
                if (line !== '') line += ',';

                line += array[i][index];
            }

            str += line + '\r\n';
        }

        return str;
    };

    const exportCSVFile = (headers, items, fileTitle) => {
        if (headers) {
            items.unshift(headers);
        }

        // Convert Object to JSON
        const jsonObject = JSON.stringify(items);

        const csv = convertToCSV(jsonObject);

        const exportedFilenmae = fileTitle + '.csv' || 'export.csv';

        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
                // feature detection
                // Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };

    const headers = {
        'Full Name': 'Full Name',
        'Department': 'Department',
        'Date of Request': 'Date of Request',
        'Type of Request': 'Type of Request',
        'Line Manager Status': 'Line Manager Status',
        'Budget Holder Status': 'Budget Holder Status',
        'P&C Status': 'P&C Status',
        'Country Manager Status': 'Country Manager Status',
        'Finance Status': 'Finance Status',
        'Total': 'Total',
    };

    const header2 = {
        'Full Name': 'Full Name',
        'Department': 'Department',
        'Date of Request': 'Date of Request',
        'Type of Request': 'Type of Request',
        'Line Manager Status': 'Line Manager Status',
        'Budget Holder Status': 'Budget Holder Status',
        'Finance Status': 'Finance Status',
        'Total': 'Total',

    }

    const fileTitle = 'Perdiem Summary';
    const fileTitle2 = 'Petty Cash Summary';
    const fileTitle3 = 'Expense Advance';

    const handleExport = () => {
        const itemsFormatted = [];

        // format the data
        request.forEach((item) => {
            itemsFormatted.push({
                'Full Name': item['user']['Full Name'],
                'Department': item['department'],
                'Date of Request': item['createdAt'],
                'Type of Request': 'Perdiem',
                'Line Manager Status': item['approvalStatus'],
                'Budget Holder Status': item['approvalStatusbh'],
                'P&C Status': item['approvalStatuspc'],
                'CM Status': item['approvalStatuscm'],
                'Finance Status': item['approvalStatusf'],
                'Total': item['currency'] + ' ' + item['TOTALCLAIM'],
            });
        });

        exportCSVFile(headers, itemsFormatted, fileTitle);
    };

    const handleExport2 = () => {
        const itemsFormatted2 = [];

        // format the data
        pettycash.forEach((item) => {
            itemsFormatted2.push({
                'Full Name': item['user']['Full Name'],
                'Department': item['details']['department'],
                'Date of Request': item['details']['createdAt'],
                'Type of Request': 'Petty Cash',
                'Line Manager Status': item['details']['approvalStatus'],
                'Budget Holder Status': item['details']['approvalStatusbh'],
                'Finance Status': item['details']['approvalStatusf'],
                'Total': item['details']['currency'] + ' ' + item['details']['finaltotal'],
            });
        });

        exportCSVFile(header2, itemsFormatted2, fileTitle2);
    };

    const handleExport3 = () => {
        const itemsFormatted2 = [];

        // format the data
        expenseAdvance.forEach((item) => {
            itemsFormatted2.push({
                'Full Name': item['user']['Full Name'],
                'Department': item['details']['department'],
                'Date of Request': item['details']['createdAt'],
                'Type of Request': 'Expense Advance',
                'Line Manager Status': item['details']['approvalStatus'],
                'Budget Holder Status': item['details']['approvalStatusbh'],
                'Finance Status': item['details']['approvalStatusf'],
                'Total': item['details']['currency'] + ' ' + item['details']['finaltotal'],
            });
        });

        exportCSVFile(header2, itemsFormatted2, fileTitle3);
    };



    // a dropdown to select the request type and then display the table based on the request type
    const [requestType, setRequestType] = useState('Perdiem');
    const handleRequestType = (e) => {
        setRequestType(e.target.value);
    };

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredRequestpd = request.filter((user) => {
        return user['user']['Full Name'].toLowerCase().includes(searchQuery.toLowerCase());
    });

    const filteredRequestpc = pettycash.filter((user) => {
        return user['user']['Full Name'].toLowerCase().includes(searchQuery.toLowerCase());
    });

    const filteredRequestea = expenseAdvance.filter((user) => {
        return user['user']['Full Name'].toLowerCase().includes(searchQuery.toLowerCase());
    });

    const formatDate = (dateString) => {
        if (!dateString || null) return "";

        const date = new Date(dateString);
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = String(date.getUTCFullYear()).slice(-2); // Get last two digits of year
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');

        return `  on ${day}/${month}/${year} at ${hours}:${minutes}`;
    }


    const itemsGrouped = {};
    const itemsGroupedpc = {};
    const itemsGroupedea = {};

    filteredRequestea.forEach((item) => {

        const tdate = item.details.createdAt;
        let date;
        if (tdate.includes('AM') || tdate.includes('PM')) {
            date = new Date(tdate);
            // console.log("AM",date)
        } else {
            const [day, month, yearAndTime] = tdate.split('/');
            const [year, time] = yearAndTime.split(', ');
            date = new Date(`${year}-${month}-${day}T${time}`);
            // console.log("date",date);
        }

        const month = date.toLocaleString('default', { month: 'long' });

        if (!itemsGroupedea[month]) {
            itemsGroupedea[month] = [];
        }

        itemsGroupedea[month].push({
            'Full Name': item['user']['Full Name'],
            'Department': item['details']['department'],
            'Date of Request': item['details']['createdAt'].split(',')[0],
            'Type of Request': item['details']['urlname'],
            'Line Manager Status': item['details']['approvalStatus'],
            'Budget Holder Status': item['details']['approvalStatusbh'],
            'Finance Status': item['details']['approvalStatusf'],
            'Total': item['details']['currency'] + ' ' + item['details']['finaltotal'],
        });
    });

    filteredRequestpc.forEach((item) => {

        const tdate = item.details.createdAt;
        let date;
        if (tdate.includes('AM') || tdate.includes('PM')) {
            date = new Date(tdate);
            // console.log("AM",date)
        } else {
            const [day, month, yearAndTime] = tdate.split('/');
            const [year, time] = yearAndTime.split(', ');
            date = new Date(`${year}-${month}-${day}T${time}`);
            // console.log("date",date);
        }

        const month = date.toLocaleString('default', { month: 'long' });
        // console.log(month)

        if (!itemsGroupedpc[month]) {
            itemsGroupedpc[month] = [];
        }

        itemsGroupedpc[month].push({
            'Full Name': item['user']['Full Name'],
            'Department': item['details']['department'],
            'Date of Request': item['details']['createdAt'].split(',')[0],
            'Type of Request': item['details']['urlname'],
            'Line Manager Status': item['details']['approvalStatus'],
            'Budget Holder Status': item['details']['approvalStatusbh'],
            'Finance Status': item['details']['approvalStatusf'],
            'Total': item['details']['currency'] + ' ' + item['details']['finaltotal'],
        });
    });

    filteredRequestpd.forEach((item) => {

        const tdate = item.createdAt;
        // console.log(tdate);
        let date;

        if (tdate && typeof tdate === 'string') {
            if (tdate.includes('AM') || tdate.includes('PM')) {
                date = new Date(tdate);
            } else {
                const [day, month, yearAndTime] = tdate.split('/');
                const [year, time] = yearAndTime.split(', ');
                date = new Date(`${year}-${month}-${day}T${time}`);
            }
        } else {
            console.log('tdate is undefined or not a string');
            // Handle the undefined or non-string tdate case here
        }

        // Additional code as needed
        // console.log('Date:', date);

        // const month = date.toLocaleString('default', { month: 'long' });
        let month = "";
        // Ensure date is a valid Date object before using toLocaleString
        if (date instanceof Date && !isNaN(date)) {
            month = date.toLocaleString('default', { month: 'long' });
            // console.log('Month:', month);
        } else {
            console.log('Invalid date, cannot get month');
        }
        // console.log(month)

        if (!itemsGrouped[month]) {
            itemsGrouped[month] = [];
        }

        itemsGrouped[month].push({
            'Full Name': item['user']['Full Name'],
            'Department': item['department'],
            'Date of Request': item['createdAt'],
            'Type of Request': 'Perdiem',
            'Line Manager Status': item['approvalStatus'],
            'Budget Holder Status': item['approvalStatusbh'],
            "approvalDatebh": item['approvalDatebh'],
            'P&C Status': item['approvalStatuspc'],
            'CM Status': item['approvalStatuscm'],
            "approvalDatepc": item['approvalDatepc'],
            'Finance Status': item['approvalStatusf'],
            "approvalDate": item['approvalDate'],
            "approvalDatecm": item['approvalDatecm'],
            'Total': item['currency'] + ' ' + item['TOTALCLAIM'],
        });
    });

    const handleMonthSelection = (month) => {
        setSelectedMonth(month);
    };

    const handleMonthSelectionpc = (month) => {
        setSelectedMonthpc(month);
    };

    const handleMonthSelectionea = (month) => {
        setSelectedMonthea(month);
    };

    // console.log("req", itemsGrouped);
    // console.log(requestType);

    if (loading) return <p>Loading, please wait...</p>;

    // if (user && department === "IT" || department === "People & Culture" || department === "Finance" || department === "Senior Management") {

    if (user) {
        return (
            <div>
                <Greeting
                    user='Manager'
                    text='These are the request you have approved and rejected'
                />

                {/* if requestType = perdiem then display the table below  */}
                {requestType === 'Petty Cash' ? (
                    <div className="table-wrapper">
                        <input type="text" placeholder="Search" value={searchQuery} onChange={handleSearch} />
                        <select value={'Request Type'} onChange={handleRequestType} className='request'>
                            {/* <option value="">Request Type</option> */}
                            <option value="Petty Cash">Petty Cash</option>
                            <option value="Perdiem">Perdiem</option>
                            <option value="Expense Advance">Expense Advance</option>
                        </select>
                        <select onChange={(e) => handleMonthSelectionpc(e.target.value)} value={selectedMonthpc} className='request'>
                            <option value="">All</option>
                            {Object.keys(itemsGroupedpc).map((month) => (
                                <option key={month} value={month}>
                                    {month}
                                </option>
                            ))}
                        </select>

                        <button className='addUser' onClick={handleExport2}>Export Data</button>

                        <table className="fl-table">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Date of Request</th>
                                    <th>
                                        Request Type
                                    </th>
                                    <th>Full Name</th>
                                    <th>Department</th>
                                    <th>Line Manager Status</th>
                                    <th>Budget Holder Status</th>
                                    <th>Finance Status</th>
                                    <th>Total</th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    selectedMonthpc ? (
                                        itemsGroupedpc[selectedMonthpc]?.map((user, i) => (
                                            <tr key={i} className={selectedRow === i ? 'highlighted-row' : ''}>
                                                <td onClick={() => handleFullNameClick(i)}>{i + 1}</td>
                                                <td>{user['Date of Request']}</td>
                                                <td>{user['Type of Request']}</td>
                                                <td>{user['Full Name']}</td>
                                                {/* <td>{user['Full Name']}</td> */}
                                                <td>{user['Department']}</td>
                                                {/* if {user['approvalStatus']} = approved set color to green, pending orange and rejected to red */}
                                                {/* <td className={user['approvalStatus'] === 'approved' ? 'highlighted-row' : null}>{user['approvalStatus']}</td> */}
                                                <td>{user['Line Manager Status']}</td>
                                                <td>{user['Budget Holder Status']}</td>
                                                {/* <td>{user['approvalStatuspc']}</td> */}
                                                <td>{user['Finance Status'] === undefined ?
                                                    "pending" :
                                                    user['Finance Status'] === "pending"
                                                        ? "pending"
                                                        : `${user['Finance Status']}${formatDate(user['approvalDate'])}`}</td>
                                                <td>{user['Total']}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        Object.entries(itemsGroupedpc).flatMap(([month, items]) =>
                                            items.map((user, i) => (
                                                <tr key={`${month}-${i}]]`} className={selectedRow === i ? 'highlighted-row' : ''}>

                                                    <td onClick={() => handleFullNameClick(i)}>{i + 1}</td>
                                                    <td>{user['Date of Request']}</td>
                                                    <td>{user['Type of Request']}</td>
                                                    <td>{user['Full Name']}</td>
                                                    {/* <td>{user['Full Name']}</td> */}
                                                    <td>{user['Department']}</td>
                                                    {/* if {user['approvalStatus']} = approved set color to green, pending orange and rejected to red */}
                                                    {/* <td className={user['approvalStatus'] === 'approved' ? 'highlighted-row' : null}>{user['approvalStatus']}</td> */}
                                                    <td>{user['Line Manager Status']}</td>
                                                    <td>{user['Budget Holder Status']}</td>
                                                    {/* <td>{user['approvalStatuspc']}</td> */}
                                                    <td>{user['Finance Status'] === undefined ?
                                                        "pending" :
                                                        user['Finance Status'] === "pending"
                                                            ? "pending"
                                                            : `${user['Finance Status']}${formatDate(user['approvalDate'])}`}</td>
                                                    <td>{user['Total']}</td>
                                                </tr>
                                            ))
                                        )
                                    )}



                            </tbody>
                        </table>
                    </div>

                ) : requestType === 'Expense Advance' ? (
                    <div className="table-wrapper">
                        <input type="text" placeholder="Search" value={searchQuery} onChange={handleSearch} />
                        <select value={'Request Type'} onChange={handleRequestType} className='request'>
                            {/* <option value="">Request Type</option> */}
                            <option value="Expense Advance">Expense Advance</option>
                            <option value="Petty Cash">Petty Cash</option>
                            <option value="Perdiem">Perdiem</option>

                        </select>
                        <select onChange={(e) => handleMonthSelectionea(e.target.value)} value={selectedMonthea} className='request'>
                            <option value="">All</option>
                            {Object.keys(itemsGroupedea).map((month) => (
                                <option key={month} value={month}>
                                    {month}
                                </option>
                            ))}
                        </select>
                        <button className='addUser' onClick={handleExport3}>Export Data</button>

                        <table className="fl-table">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Date of Request</th>
                                    <th>
                                        Request Type
                                    </th>
                                    <th>Full Name</th>
                                    <th>Department</th>
                                    <th>Line Manager Status</th>
                                    <th>Budget Holder Status</th>
                                    <th>Finance Status</th>
                                    <th>Total</th>

                                </tr>
                            </thead>
                            <tbody>
                                {selectedMonthea ? (
                                    itemsGroupedea[selectedMonthea].map((user, i) => (
                                        <tr key={i} className={selectedRow === i ? 'highlighted-row' : ''}>
                                            <td onClick={() => handleFullNameClick(i)}>{i + 1}</td>
                                            <td>{user['Date of Request']}</td>
                                            <td>{user['Type of Request']}</td>
                                            <td>{user['Full Name']}</td>
                                            {/* <td>{user['Full Name']}</td> */}
                                            <td>{user['Department']}</td>
                                            {/* if {user['approvalStatus']} = approved set color to green, pending orange and rejected to red */}
                                            {/* <td className={user['approvalStatus'] === 'approved' ? 'highlighted-row' : null}>{user['approvalStatus']}</td> */}
                                            <td>{user['Line Manager Status']}</td>
                                            <td>{user['Budget Holder Status']}</td>
                                            {/* <td>{user['approvalStatuspc']}</td> */}
                                            <td>{user['Finance Status'] === undefined ?
                                                "pending" :
                                                user['Finance Status'] === "pending"
                                                    ? "pending"
                                                    : `${user['Finance Status']}${formatDate(user['approvalDate'])}`}</td>
                                            <td>{user['Total']}</td>
                                        </tr>
                                    ))
                                ) : (
                                    Object.entries(itemsGroupedea).flatMap(([month, items]) =>
                                        items.map((user, i) => (
                                            <tr key={`${month}-${i}]]`} className={selectedRow === i ? 'highlighted-row' : ''}>

                                                <td onClick={() => handleFullNameClick(i)}>{i + 1}</td>
                                                <td>{user['Date of Request']}</td>
                                                <td>{user['Type of Request']}</td>
                                                <td>{user['Full Name']}</td>
                                                {/* <td>{user['Full Name']}</td> */}
                                                <td>{user['Department']}</td>
                                                {/* if {user['approvalStatus']} = approved set color to green, pending orange and rejected to red */}
                                                {/* <td className={user['approvalStatus'] === 'approved' ? 'highlighted-row' : null}>{user['approvalStatus']}</td> */}
                                                <td>{user['Line Manager Status']}</td>
                                                <td>{user['Budget Holder Status']}</td>
                                                {/* <td>{user['approvalStatuspc']}</td> */}
                                                <td>{user['Finance Status'] === undefined ?
                                                    "pending" :
                                                    user['Finance Status'] === "pending"
                                                        ? "pending"
                                                        : `${user['Finance Status']}${formatDate(user['approvalDate'])}`}</td>
                                                <td>{user['Total']}</td>
                                            </tr>
                                        ))
                                    )
                                )}



                            </tbody>
                        </table>
                    </div>

                ) : (
                    <div className="table-wrapper">
                        <input type="text" placeholder="Search" value={searchQuery} onChange={handleSearch} />
                        <button className='addUser' onClick={handleExport}>Export Data</button>
                        <select value={'Request Type'} onChange={handleRequestType} className='request'>
                            {/* <option value="">Request Type</option> */}
                            <option value="Perdiem">Perdiem</option>
                            <option value="Petty Cash">Petty Cash</option>
                            <option value="Expense Advance">Expense Advance</option>
                        </select>
                        <select onChange={(e) => handleMonthSelection(e.target.value)} value={selectedMonth} className='request'>
                            <option value="">All</option>
                            {Object.keys(itemsGrouped).map((month) => (
                                <option key={month} value={month}>
                                    {month}
                                </option>
                            ))}
                        </select>
                        <table className="fl-table">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Date of Request</th>
                                    <th>
                                        Request Type
                                    </th>
                                    <th>Full Name</th>
                                    <th>Department</th>
                                    <th>Line Manager Status</th>
                                    <th>Budget Holder Status</th>
                                    <th>P&C Status</th>
                                    <th>CM Status</th>
                                    <th>Finance Status</th>
                                    <th>Total</th>

                                </tr>
                            </thead>
                            <tbody>
                                {selectedMonth ? (
                                    itemsGrouped[selectedMonth].map((user, i) => (
                                        <tr key={i} className={selectedRow === i ? 'highlighted-row' : ''}>
                                            <td onClick={() => handleFullNameClick(i)}>{i + 1}</td>
                                            <td>{user['Date of Request']}</td>
                                            <td>{user['Type of Request']}</td>
                                            <td onClick={() => handleFullNameClick(i)}>{user['Full Name']}</td>
                                            <td>{user['Department']}</td>
                                            <td>{user['Line Manager Status']}</td>
                                            <td>
                                                {user['Budget Holder Status'] === "pending"
                                                    ? "pending"
                                                    : `${user['Budget Holder Status']}${formatDate(user['approvalDatebh'])}`}
                                            </td>
                                            <td>
                                                {user['P&C Status'] === "pending"
                                                    ? "pending"
                                                    : `${user['P&C Status']}${formatDate(user['approvalDatepc'])}`}
                                            </td>
                                            <td>

                                                {
                                                    user['CM Status'] === undefined ?
                                                        "" :
                                                        user['CM Status'] === "pending" ?
                                                            "pending" :
                                                            `${user['CM Status']}${formatDate(user['approvalDatecm'])}`
                                                }
                                            </td>
                                            <td>
                                                {user['Finance Status'] === "pending"
                                                    ? "pending"
                                                    : `${user['Finance Status']}${formatDate(user['approvalDate'])}`}
                                            </td>
                                            <td>{user['Total']}</td>
                                        </tr>
                                    ))
                                ) : (
                                    Object.entries(itemsGrouped).flatMap(([month, items]) =>
                                        items.map((user, i) => (
                                            <tr key={`${month}-${i}]]`} className={selectedRow === i ? 'highlighted-row' : ''}>

                                                <td onClick={() => handleFullNameClick(i)}>{i + 1}</td>
                                                <td>{user['Date of Request']}</td>
                                                <td>{user['Type of Request']}</td>
                                                <td onClick={() => handleFullNameClick(i)}>{user['Full Name']}</td>
                                                <td>{user['Department']}</td>
                                                <td>{user['Line Manager Status']}</td>
                                                <td>
                                                    {user['Budget Holder Status'] === "pending"
                                                        ? "pending"
                                                        : `${user['Budget Holder Status']}${formatDate(user['approvalDatebh'])}`}
                                                </td>
                                                <td>
                                                    {user['P&C Status'] === "pending"
                                                        ? "pending"
                                                        : `${user['P&C Status']}${formatDate(user['approvalDatepc'])}`}
                                                </td>
                                                <td>
                                                    {
                                                        user['CM Status'] === undefined ?
                                                            "" :
                                                            user['CM Status'] === "pending" ?
                                                                "pending" :
                                                                `${user['CM Status']}${formatDate(user['approvalDatecm'])}`
                                                    }
                                                </td>
                                                <td>
                                                    {user['Finance Status'] === "pending"
                                                        ? "pending"
                                                        : `${user['Finance Status']}${formatDate(user['approvalDate'])}`}
                                                </td>
                                                <td>{user['Total']}</td>
                                            </tr>
                                        ))
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>

                )}


                <style >{`
                *{
                    box-sizing: border-box;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                }
                
        
                
                /* Table Styles */

                .dropdown-button {
                    background-color: #f9f9f9;
                    border: none;
                    padding: 5px 10px;
                    text-align: left;
                    cursor: pointer;
                    width: 100%;
                    font-size: 16px;
                  }
                  
                  .dropdown-button:hover {
                    background-color: #e1e1e1;
                  }
                  
                
                .table-wrapper{
                    margin: 11px 20px 70px;
                    box-shadow: 0px 35px 50px rgba( 0, 0, 0, 0.2 );
                    overflow-x: auto;
                    
                }
                .month-header {
                    font-weight: bold;
                    background-color: #f0f0f0; /* or any other color for highlighting */
                    font-size: 50px;
                    cursor: pointer;
                  }
                
                .fl-table {
                    border-radius: 5px;
                    font-size: 12px;
                    font-weight: normal;
                    border: none;
                    border-collapse: collapse;
                    width: 100%;
                    max-width: 100%;
                    white-space: nowrap;
                    background-color: white;
                    overflow-x: auto;
                }
                
                .fl-table td, .fl-table th {
                    text-align: center;
                    padding: 8px;
                }
                
                .fl-table td {
                    border-right: 1px solid #f8f8f8;
                    font-size: 12px;
                }
                
                .fl-table thead th {
                    color: #ffffff;
                    background: #dcc57d;
                }
                
                
                .fl-table thead th:nth-child(odd) {
                    color: #ffffff;
                    background: #434169;
                }
                
                /* add a fix horizontal scroll */
                .fl-table tbody {
                    overflow-y: auto;
                    overflow-x: hidden;
                }
                
                
                /* Responsive */
                
                @media (max-width: 767px) {
                    .fl-table {
                        display: block;
                        width: 100%;
                    }
                    .table-wrapper:before{
                        content: "Scroll horizontally >";
                        display: block;
                        text-align: right;
                        font-size: 11px;
                        color: white;
                        padding: 0 0 10px;
                    }
                    .fl-table thead, .fl-table tbody, .fl-table thead th {
                        display: block;
                    }
                    .fl-table thead th:last-child{
                        border-bottom: none;
                    }
                    .fl-table thead {
                        float: left;
                    }
                    .fl-table tbody {
                        width: auto;
                        position: relative;
                        overflow-x: auto;
                    }
                    .fl-table td, .fl-table th {
                        padding: 20px .625em .625em .625em;
                        height: 60px;
                        vertical-align: middle;
                        box-sizing: border-box;
                        overflow-x: hidden;
                        overflow-y: auto;
                        width: 120px;
                        font-size: 13px;
                        text-overflow: ellipsis;
                    }
                    .fl-table thead th {
                        text-align: left;
                        border-bottom: 1px solid #f7f7f9;
                    }
                    .fl-table tbody tr {
                        display: table-cell;
                    }
                    .fl-table tbody tr:nth-child(odd) {
                        background: none;
                    }
                    .fl-table tr:nth-child(even) {
                        background: transparent;
                    }
                    .fl-table tr td:nth-child(odd) {
                        background: #F8F8F8;
                        border-right: 1px solid #E6E4E4;
                    }
                    .fl-table tr td:nth-child(even) {
                        border-right: 1px solid #E6E4E4;
                    }
                    .fl-table tbody td {
                        display: block;
                        text-align: center;
                    }
                }

                  a{
                    padding: 5px 10px;
                  }
                  
                  .addUser {
                    background-color: #434169;
                    border: none;
                    color: white;
                    padding: 5px 10px;
                    text-align: center;
                    text-decoration: none;
                    display: inline-block;
                    font-size: 14px;
                    margin: 2px;
                    cursor: pointer;
                    float: right;
                  }

                  .request {
                    background-color: #434169;
                    border: none;
                    color: white;
                    padding: 5px 10px;
                    text-align: center;
                    text-decoration: none;
                    display: inline-block;
                    font-size: 14px;
                    margin: 2px;
                    cursor: pointer;
                    
                  }
                  
                  .search {
                    margin: 20px;
                  }  
                  
                  .highlighted-row {
                    background-color: yellow;
                  }


            `}</style>

            </div>

        );
    }
    if (!user) {
        return (
            <div>
                Please <Link to='/'>LOGIN</Link> to view forms
            </div>
        );
    }

    // return (
    //     <div>
    //         <h5>You must be from the IT or PC department to view this page</h5>
    //     </div>
    // );


}

export default ViewRequest;

